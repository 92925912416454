/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rich-content {
  overflow: hidden; }

.rc-two-cols .rc-rte {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 2px solid #C10001; }

.rc-video .thumb::after,
.rc-video-left .thumb::after,
.rc-video-right .thumb::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(193, 0, 1, 0.8); }

.rc-btn {
  text-align: center; }
  .rc-btn .btn {
    padding: 12px 20px;
    font-size: 1.15rem; }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOSTRAP OVERRIDES
 */
/* text */
.h1, h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #3A3A3C; }

.h2, h2 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #C10001; }

.h3, h3 {
  margin-bottom: 1rem;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

.h4, h4 {
  margin-bottom: 1rem;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .01rem;
  text-transform: uppercase;
  color: #C10001; }

.h5, h5 {
  margin-bottom: 1rem;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .01rem;
  text-transform: uppercase;
  color: #5B5D5F; }

.h6, h6 {
  line-height: 1.5;
  margin-bottom: .5rem;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-size: .9rem;
  letter-spacing: .01rem;
  text-transform: uppercase;
  color: #3A3A3C; }

a {
  color: #C10001; }
  a:hover {
    color: #3A3A3C;
    text-decoration: underline; }

.bg-inverse {
  background-color: #3A3A3C !important; }

.blockquote {
  font-style: italic;
  text-align: center;
  border-left: none; }
  .blockquote footer {
    font-style: normal;
    font-size: 1.1rem; }

/* buttons */
.btn {
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-size: .8rem;
  line-height: 1;
  transition: color .2s ease-out, background .2s ease-out; }

.btn.disabled, .btn:disabled {
  opacity: 1; }

.btn-primary {
  background-color: #C10001;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-primary:focus, .btn-primary.focus, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:focus, .btn-primary.active.focus {
    outline: none;
    background-color: #a80001; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:focus {
    background-color: #db0001; }
  .btn-primary:active, .btn-primary.active {
    background-color: #a80001; }
  .btn-primary.disabled:hover, .btn-primary[disabled]:hover,
  fieldset[disabled] .btn-primary:hover {
    background-color: #C10001; }

.btn-secondary {
  color: #fff;
  background-color: #3A3A3C;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-secondary:focus, .btn-secondary.focus, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:focus, .btn-secondary.active.focus {
    outline: none;
    color: #fff;
    background-color: #010101; }
  .btn-secondary:hover, .btn-secondary.hover, .btn-secondary:focus {
    color: #fff;
    background-color: #474749; }
  .btn-secondary:active, .btn-secondary.active {
    color: #fff;
    background-color: #010101; }
  .btn-secondary.disabled, .btn-secondary[disabled],
  fieldset[disabled] .btn-secondary {
    color: #fff;
    background-color: #3A3A3C; }
    .btn-secondary.disabled:hover, .btn-secondary[disabled]:hover,
    fieldset[disabled] .btn-secondary:hover {
      background-color: #3A3A3C; }
    .btn-secondary.disabled:active, .btn-secondary[disabled]:active,
    fieldset[disabled] .btn-secondary:active {
      color: #fff; }

/* forms */
.form-control {
  border-radius: 0; }
  .form-control:focus {
    border-color: #3A3A3C; }

.form-control-label {
  text-transform: uppercase; }

/* navbar */
.navbar {
  padding: 0;
  border-radius: 0; }

.navbar-nav .nav-link {
  padding: .8rem 1rem;
  text-transform: uppercase;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  letter-spacing: .01rem;
  line-height: 1;
  transition: background .2s ease-out;
  color: #3A3A3C; }
  .navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
    color: #C10001;
    text-decoration: underline; }
  .navbar-nav .nav-link:active {
    color: #8e0001; }

.navbar-nav .nav-item + .nav-item {
  position: relative;
  margin-left: 0; }
  .navbar-nav .nav-item + .nav-item::before {
    content: '';
    display: block;
    width: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    position: absolute;
    top: .5rem;
    left: 0;
    bottom: .5rem;
    margin-left: -1px; }

.navbar-nav .active > .nav-link, .navbar-nav .active > .nav-link:hover, .navbar-nav .active > .nav-link:focus {
  color: #C10001;
  font-weight: bold;
  background-color: transparent; }

.dropdown-menu {
  border-radius: 0; }

.navbar-toggler {
  width: 100%;
  color: #3A3A3C;
  text-transform: uppercase;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  text-align: left;
  font-size: 1rem;
  background-image: none !important;
  cursor: pointer;
  border: 1px solid #3A3A3C; }
  .navbar-toggler[aria-expanded="true"] {
    border-color: #C10001;
    color: #C10001; }

.navbar-toggleable-sm .navbar-nav {
  margin: 0; }

/* card */
.card {
  width: 510px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  border-radius: 0;
  border: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.card--top {
  margin-bottom: 40px; }

.card-block {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-img-top {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0;
  padding: 1.25rem 1.25rem 0; }
  .card-img-top img {
    max-width: 100%; }

.card-link {
  margin-top: auto;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase; }

.card-title {
  color: #C10001;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .01rem; }

.card-text i {
  margin-right: 3px; }

.card--dark,
.card--darker,
.card--darkest {
  color: #fff; }
  .card--dark .card-title,
  .card--darker .card-title,
  .card--darkest .card-title {
    color: #fff; }
  .card--dark .card-link,
  .card--darker .card-link,
  .card--darkest .card-link {
    color: #fff; }
  .card--dark .card-img-top,
  .card--dark .card-block,
  .card--darker .card-img-top,
  .card--darker .card-block,
  .card--darkest .card-img-top,
  .card--darkest .card-block {
    text-align: center; }
  .card--dark img,
  .card--darker img,
  .card--darkest img {
    width: 100px; }
  .card--dark a,
  .card--darker a,
  .card--darkest a {
    color: #fff; }
  .card--dark .card-link,
  .card--darker .card-link,
  .card--darkest .card-link {
    padding: 5px;
    transition: color .15s ease-out, background .15s ease-out; }
    .card--dark .card-link:hover,
    .card--darker .card-link:hover,
    .card--darkest .card-link:hover {
      color: #C10001;
      background-color: #fff;
      text-decoration: none; }
    .card--dark .card-link:active,
    .card--darker .card-link:active,
    .card--darkest .card-link:active {
      color: #C10001;
      background-color: rgba(255, 255, 255, 0.9); }

.card--dark {
  background-color: #cf4e4f; }

.card--darker {
  background-color: #c10001; }

.card--darkest {
  background-color: #921c1d; }

/* media */
.media {
  margin-bottom: 3rem; }

.media-left {
  width: 33.33%;
  padding-right: 20px; }

.media-image {
  display: block;
  width: 100%;
  padding-bottom: 85%;
  background-size: cover;
  background-position: center center; }

/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  position: relative;
  min-height: 100%;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  background-color: #F2F1F1;
  font-size: .9rem;
  color: #5B5D5F;
  padding-top: 80px;
  padding-bottom: 75px; }

/*
 *	UTILITIES
 */
.flex-vcenter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.flex-stretch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  .flex-stretch > * {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    min-height: 2px; }

/*
 *	GENERAL
 */
.title-default {
  line-height: 1.1;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .01rem;
  text-transform: uppercase;
  color: #C10001; }

.title-big {
  margin-bottom: 35px;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: bolder;
  text-transform: uppercase;
  color: #5B5D5F; }
  .title-big span {
    padding: .1rem .7rem;
    background-color: rgba(242, 241, 241, 0.8); }

.title-big--white {
  color: white; }

.link-fancy {
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase; }

.with-errors {
  color: #d9534f; }

/*
 *	HEADER
 */
.header {
  z-index: 99;
  position: fixed;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 0 5px;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow-y: scroll; }

.banner-space {
  height: 140px; }

.logo {
  max-width: 100%;
  margin-bottom: auto; }
  .logo a {
    display: block;
    max-width: 100%;
    color: #3A3A3C;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1; }
    .logo a:hover, .logo a:focus {
      color: #C10001; }
  .logo img {
    width: 120px;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle; }
  .logo span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px; }

/*
 *	BANNER
 */
.banner {
  width: 100%;
  margin-bottom: 40px;
  /*background-color: rgba(#000, .05);*/ }
  .banner img {
    margin: 0 auto;
    width: 100%;
    max-width: 100%; }

/*
 *	MAIN
 */
.main {
  margin-bottom: 35px;
  padding: 20px;
  background-color: #fff; }

/*
 *	HOME
 */
.project-thumb {
  margin-bottom: 30px; }
  .project-thumb a {
    display: block;
    width: 100%;
    position: relative; }
    .project-thumb a::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(193, 0, 1, 0.8);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("/img/icon-zoom.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 50px;
      opacity: 0;
      transition: opacity .2s ease-out; }
    .project-thumb a:hover::before {
      opacity: 1; }
  .project-thumb img {
    width: 100%; }

/*
 *	REALISATIES
 */
.filters a + a {
  margin-left: 20px; }

.project {
  margin-bottom: 30px; }
  .project a {
    display: block;
    position: relative; }
    .project a::before {
      z-index: 1;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50px;
      height: 50px;
      background-color: rgba(193, 0, 1, 0.8);
      background-image: url("/img/icon-zoom.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30px;
      transition: height .2s ease-out, width .2s ease-out, background .2s ease-out; }

.project__img img {
  width: 100%; }

.project__title {
  z-index: 1;
  position: absolute;
  right: 50px;
  bottom: 0;
  left: 0;
  min-height: 50px;
  background-color: #F2F1F1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  transition: right .2s ease-out; }
  .project__title .title {
    margin: 0;
    padding: 0 10px;
    font-family: "Ruda", Helvetica, Arial, sans-serif;
    font-size: .9rem;
    line-height: 1.1;
    text-transform: uppercase;
    color: #3A3A3C; }

.project a:hover::before, .project a:focus::before {
  width: 100%;
  height: 100%;
  background-size: 50px; }

.project a:hover .project__title, .project a:focus .project__title {
  right: 0;
  background-color: transparent; }
  .project a:hover .project__title .title, .project a:focus .project__title .title {
    color: #fff;
    font-weight: bold;
    text-align: center; }

/*
 *	SUBSCRIBE
 */
.event-subscribe {
  padding: 20px;
  background-color: #F2F1F1; }

/*
 *	CONTACT
 */
.contact-info {
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  color: #3A3A3C; }
  .contact-info a {
    color: #3A3A3C; }
    .contact-info a:hover, .contact-info a:focus {
      color: #C10001; }

.map {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #F2F1F1;
  border: 1px solid #F2F1F1; }
  .map #map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/*
 *	SITEMAP
 */
.sitemap {
  font-size: 1.2rem; }
  .sitemap ul {
    padding-left: 0;
    list-style: none; }
  .sitemap li {
    margin-bottom: .5rem; }
  .sitemap ul ul {
    margin-top: .5rem;
    padding-left: 25px;
    font-size: 1.1rem; }

/*
 *	FOOTER
 */
.footer {
  margin-top: 35px; }

.footer__top {
  position: relative;
  padding: 20px;
  color: #fff;
  background-color: #C10001;
  overflow: hidden; }
  .footer__top .title-default {
    color: #fff; }
  .footer__top a {
    color: #fff; }
  .footer__top::before {
    content: '';
    display: block;
    width: 200%;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-22deg);
    margin-left: -60px; }
  .footer__top .btn {
    height: 38px; }

.footer__bottom {
  padding: 10px 15px;
  background-color: #3A3A3C;
  color: #fff;
  font-family: "Ruda", Helvetica, Arial, sans-serif;
  font-size: .8rem;
  text-transform: uppercase; }
  .footer__bottom::after {
    content: '';
    display: table;
    clear: both; }
  .footer__bottom a {
    color: #fff; }
  .footer__bottom .links {
    margin: 0;
    padding: 0;
    list-style: none; }
    .footer__bottom .links li {
      display: inline-block; }
    .footer__bottom .links li + li {
      margin-left: .5rem; }
      .footer__bottom .links li + li::before {
        content: '/';
        margin-right: .5rem; }

.credits {
  opacity: .8;
  margin-top: 30px;
  text-align: center; }
  .credits a {
    color: #5B5D5F; }

.social a {
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #fff;
  color: #C10001; }

/*
 *	FLEXSLIDER
 */
.flexslider {
  margin-bottom: 20px;
  border: none; }
  .flexslider .slides a {
    display: block; }

.flex-direction-nav a {
  margin-top: -42px;
  width: 45px;
  height: 85px;
  font-size: 0;
  background-size: 40px auto;
  background-repeat: no-repeat;
  background-position: center center; }
  .flex-direction-nav a::before {
    content: none; }

.flex-direction-nav a.flex-next::before,
.flex-direction-nav a.flex-prev::before {
  content: none; }

.flex-direction-nav .flex-prev {
  background-image: url("/img/angle-left.png"); }

.flex-direction-nav .flex-next {
  background-image: url("/img/angle-right.png"); }

#slider-nav .flex-active-slide a {
  border: 3px solid #C10001; }

#slider-nav .flex-direction-nav a {
  width: 35px;
  background-size: 30px auto; }

.square-thumb {
  width: 100%;
  padding-bottom: 100%;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat; }

/*
 *	IMPORTS
 */
@font-face {
  font-family: 'fontello';
  src: url("/font/fontello.eot?16179476");
  src: url("/font/fontello.eot?16179476#iefix") format("embedded-opentype"), url("/font/fontello.woff2?16179476") format("woff2"), url("/font/fontello.woff?16179476") format("woff"), url("/font/fontello.ttf?16179476") format("truetype"), url("/font/fontello.svg?16179476#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/font/fontello.svg?16179476#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-cancel:before {
  content: '\e800'; }

/* '' */
.icon-mail:before {
  content: '\e801'; }

/* '' */
.icon-phone:before {
  content: '\e802'; }

/* '' */
.icon-location:before {
  content: '\e803'; }

/* '' */
.icon-twitter:before {
  content: '\f099'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
.icon-fax:before {
  content: '\f1ac'; }

/* '' */
.icon-pinterest:before {
  content: '\f231'; }

/* '' */
/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0; }
  .ws-cart-item:last-of-type {
    border-bottom: 1px solid #f0f0f0; }

.ws-cart-item__img {
  -ms-flex: 1 0 17%;
  flex: 1 0 17%;
  max-width: 17%; }

.ws-cart-item__details {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem; }

.ws-cart-item__actions {
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem; }
  .ws-cart-item__actions .price {
    margin-bottom: 1rem; }
    .ws-cart-item__actions .price::after {
      content: '';
      display: table;
      clear: both; }
  .ws-cart-item__actions .amount {
    float: left; }
    .ws-cart-item__actions .amount input {
      display: inline-block;
      margin-left: .5rem;
      width: 60px; }
  .ws-cart-item__actions .remove {
    clear: both; }

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem; }
  .ws-cart-dropdown .ws-cart-list {
    max-width: 350px; }
    .ws-cart-dropdown .ws-cart-list .ws-cart-item p {
      margin-bottom: 5px; }
  .ws-cart-dropdown .ws-cart-item__actions .price {
    margin-bottom: 0; }
  .ws-cart-dropdown .ws-cart-item {
    padding: .5rem 0; }
  .ws-cart-dropdown .ws-cart-actions {
    padding: 1rem 0 0 0; }
    .ws-cart-dropdown .ws-cart-actions:after {
      content: '';
      display: block;
      clear: both; }
  .ws-cart-dropdown .ws-cart-total {
    padding: 10px;
    background-color: #F8F8F8; }

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff; }

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd; }
  .ws-info-box ul {
    padding-left: 0; }
  .ws-info-box li {
    list-style: none; }
  .ws-info-box .price {
    float: right; }
  .ws-info-box .table td {
    border-top: none; }
    .ws-info-box .table td:last-child {
      text-align: right; }
  .ws-info-box tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .ws-info-box tfoot tr:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd; }
  .ws-continue .btn {
    width: 100%; }

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0; }
  .ws-continue__logos li {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem; }

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }

.ws-progress .nav-item {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%; }

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd; }
  .ws-progress .nav-link.active {
    font-weight: bold;
    font-style: italic; }

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5); }

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5; }

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec; }

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4; }

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }
  .ws-payment-options li {
    display: inline-block;
    width: 50px;
    margin: 0 3px 5px 0; }
  .ws-payment-options img {
    max-width: 100%; }

.ws-pay-icon {
  width: 35px;
  vertical-align: middle; }

@media (max-width: 543px) {
  .ws-progress .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ws-progress .nav-item {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .ws-progress .nav-link {
    padding: .5rem; } }

/*
 *	Tijdelijke hacks en bugfixes
 */
/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (max-width: 991px) {
  .banner-space {
    height: 120px; }
  .navbar-nav {
    background-color: #F2F1F1; }
    .navbar-nav .nav-link {
      padding: .5rem 1rem; } }

@media (max-width: 767px) {
  .logo img {
    width: 90px; }
  .navbar-nav .nav-item + .nav-item::before {
    content: none;
    border-top: 1px solid #F2F1F1; }
  body {
    padding-top: 65px; } }

@media (max-width: 543px) {
  .footer__bottom .links {
    margin-top: 1rem; }
    .footer__bottom .links li {
      display: block; }
    .footer__bottom .links li + li {
      margin-left: 0; }
      .footer__bottom .links li + li::before {
        content: none; } }

@media (max-width: 310px) {
  .logo span {
    display: none; } }

/*# sourceMappingURL=app.css.map */
